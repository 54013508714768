import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapGetters} from "vuex";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import SubscriptionApiService from "@/core/services/api.service.subscription";
import ConfirmDialog from "@/view/pages/ConfirmDialog";


export default {
    props: {
        modal: {
            default: false
        },
        value: Boolean
    },

    name: "shop-subscriptions",
    data() {
        return {
            requiredRule: [v => !!v || "Field is required"],
            isSaving: false,
            loading: false,
            shopId: null,
            shopName: "",
            valid: false,
            addGiftDisable: false,
            giftValue: "",
            validTo: null,
            shopSubscriptions: [],
            happyHour: false,
            offerOfTheDay: false,
            specialOffer: false,
            shoppingEvent: false,
            happyHourOld: false,
            offerOfTheDayOld: false,
            specialOfferOld: false,
            shoppingEventOld: false,
            promotionTypes: [],
            items: [
                {
                    postPromotionType: 'Happy Hour',
                    active: true
                },
                {
                    postPromotionType: 'Offer of the day',
                    active: false
                },
                {
                    postPromotionType: 'Special event',
                    active: false
                },
                {
                    active: 'Shopping event',
                    value: true
                }
            ],
            giftTableHeaders: [
                {text: "Value", value: "value", sortable: false},
                {text: "Created", value: "created", sortable: false},
                {text: "Valid To", value: "validTo", sortable: false},
                {text: "Used", value: "used", sortable: false},
                {text: "Delete", value: "delete", sortable: false}
            ],
        };
    },
    async created() {
        this.loading = true;
        await SubscriptionApiService.init();
        this.shopId = this.$route.params.shopId;
        this.shopName = this.$route.params.shopName;
        this.$log.debug("shopid: ", this.shopId);
        this.$store.dispatch(SET_BREADCRUMB, [{title: this.$i18n.t('promoSub.subFor') + this.shopName}]);
        this.retrieveSubscriptions(this.shopId);
    },

    methods: {
        retrieveSubscriptions(shopId) {
            if (shopId != null) {
                SubscriptionApiService.get(`/GetPromotionTypesForShop`, `${shopId}`)
                    .then(response => {
                        this.$log.debug("PromotionTypes: ", response.data);
                        this.promotionTypes = response.data;
                        for (let i = 0; i < this.promotionTypes.length; i++) {
                            this.$log.debug("PromotionType: ",this.promotionTypes[i]);
                            if (this.promotionTypes[i].postPromotionType === "HAPPY_HOUR") {
                                this.happyHour = this.promotionTypes[i].active;
                                this.happyHourOld = this.promotionTypes[i].active;
                            }
                            if (this.promotionTypes[i].postPromotionType === "OFFER_OF_THE_DAY") {
                                this.offerOfTheDay = this.promotionTypes[i].active;
                                this.offerOfTheDayOld = this.promotionTypes[i].active;
                            }
                            if (this.promotionTypes[i].postPromotionType === "SPECIAL_OFFER") {
                                this.specialOffer = this.promotionTypes[i].active;
                                this.specialOfferOld = this.promotionTypes[i].active;
                            }
                            if (this.promotionTypes[i].postPromotionType === "SHOPPING_EVENT") {
                                this.shoppingEvent = this.promotionTypes[i].active;
                                this.shoppingEventOld = this.promotionTypes[i].active;
                            }
                        }

                    })
                    .catch(error => {
                        this.$log.error("Error: ", error);
                        this.errored = true;
                    })
                    .finally(() => (this.loading = false));
            }
        },
        previousState() {
            this.$router.go(-1);
        },
        validateAndSave() {
            if (this.$refs.form.validate()) {
                this.$log.debug("happyHour: " + this.happyHour);
                this.$log.debug("happyHourOld: " + this.happyHourOld);
                this.$log.debug("offerOfTheDay: " + this.offerOfTheDay);
                this.$log.debug("offerOfTheDayOld: " + this.offerOfTheDayOld);
                this.$log.debug("specialEvent: " + this.specialOffer);
                this.$log.debug("specialEventOld: " + this.specialOfferOld);
                this.$log.debug("shoppingEvent: " + this.shoppingEvent);
                this.$log.debug("shoppingEventOld: " + this.shoppingEventOld);
                if (this.happyHour !== this.happyHourOld){
                    if (this.happyHour){
                        this.activatePromotion(this.shopId, "HAPPY_HOUR");
                    }else{
                        this.deactivatePromotion(this.shopId, "HAPPY_HOUR");
                    }
                }
                if (this.offerOfTheDay !== this.offerOfTheDayOld){
                    if (this.offerOfTheDay){
                        this.activatePromotion(this.shopId, "OFFER_OF_THE_DAY");
                    }else{
                        this.deactivatePromotion(this.shopId, "OFFER_OF_THE_DAY");
                    }
                }
                if (this.specialOffer !== this.specialOfferOld){
                    if (this.specialOffer){
                        this.activatePromotion(this.shopId, "SPECIAL_OFFER");
                    }else{
                        this.deactivatePromotion(this.shopId, "SPECIAL_OFFER");
                    }
                }
                if (this.shoppingEvent !== this.shoppingEventOld){
                    if (this.shoppingEvent){
                        this.activatePromotion(this.shopId, "SHOPPING_EVENT");
                    }else{
                        this.deactivatePromotion(this.shopId, "SHOPPING_EVENT");
                    }
                }
                this.previousState();
            }
        },
        activatePromotion(shopId, promotionType){
            SubscriptionApiService.post(`/ActivatePromotionTypeForShop?shopId=${shopId}&promotionType=${promotionType}`)
                .then(response => {
                    this.$log.debug("Promotion Activated: " + response);
                })
                .catch(error => {
                    this.$log.error("Error: ", error);
                    this.errored = true;
                })
                .finally(() => {
                    this.loading = false;
                    this.isSaving = false;
                });
        },
        deactivatePromotion(shopId, promotionType){
            let params = {};
            params["shopId"] = shopId;
            params["promotionType"] = promotionType;
            SubscriptionApiService.post(`/DeactivatePromotionTypeForShop?shopId=${shopId}&promotionType=${promotionType}`)
                .then(response => {
                    this.$log.debug("Promotion Deactivated: " + response);
                })
                .catch(error => {
                    this.$log.error("Error: ", error);
                    this.errored = true;
                })
                .finally(() => {
                    this.loading = false;
                    this.isSaving = false;
                });
        }
    },
    components: {
        ConfirmDialog
    },
    computed: {
        ...mapGetters(["currentUserPersonalInfo"]),
        show: {
            get() {
                return this.value;
            },
            set(value) {
                if (!value) {
                    this.$emit("input", value);
                }
            }
        }
    }
};
